<template>
  <div>
    <v-layout>
      <v-btn
        icon
        class="primary--text mt-1 mr-2"
        :to="`/${projectId}/${namespaceId}/n/applications`"
        router
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Application Allocations</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-container fluid>
          <v-row>
            <v-btn class="primary--text mr-3" text @click="refresh()">
              <v-icon class="mr-1">refresh</v-icon>
              Refresh
            </v-btn>
            <v-btn
              class="primary--text mr-3"
              text
              exact
              :to="{
                name: 'applicationDetails',
                params: {
                  project: projectId,
                  namespace: namespaceId,
                  application: applicationId,
                },
              }"
              router
            >
              <v-icon class="mr-1">edit</v-icon>
              Edit Application
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </v-layout>
    <v-layout>
      <page-subtitle
        >View application allocation status on each device where it is
        scheduled.</page-subtitle
      >
    </v-layout>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && applicationAllocations.length === 0 && selectedStatuses.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Application Allocations</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center mt-4">
                    Here you will see your application allocations on each
                    scheduled device.
                  </page-subtitle>
                </span>
                <v-btn
                  class="mt-3"
                  outlined
                  color="primary"
                  rel="noopener"
                  target="_blank"
                  href="https://docs.synpse.net/applications/deploy"
                >
                  View examples
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else outlined>
          <v-card-title>
            <v-container fluid>
              <v-row justify="space-between">
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="selectedStatuses"
                    :items="availableStatuses"
                    label="App Status"
                    multiple
                    chips
                    hint="Filter based on status"
                    persistent-hint
                  ></v-select>
                </v-col>
                
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="2"
                >                 
                 <v-switch
                    v-model="autoRefresh"
                    class="pb-0"
                    :label="`Auto Refresh (${autoRefresh ? 'on' : 'off' }) `"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
           
          </v-card-title>
          <v-data-table
            v-if="applicationAllocations.length > 0"
            :key="tableKey"
            :headers="headers"
            :items="applicationAllocations"
            :search="e3"
          >
            <template v-slot:[`item.deviceId`]="{ item }">
              <!-- TODO: link to device details page once we have a page for it -->
              <router-link
                :to="{
                  name: 'deviceDetails',
                  params: {
                    project: item.projectId,
                    device: item.deviceId,
                  },
                }"
                >{{ item.deviceId }}</router-link
              >
            </template>

            <template v-slot:[`item.applicationStatuses`]="{ item }">
              <div v-if="item.applicationStatuses">
                <template
                  v-for="(applicationStatus, i) in item.applicationStatuses"
                >
                  <v-tooltip
                    v-if="applicationStatus.state === 'running'"
                    bottom
                    :key="`${item.applicationId}-${i}`"
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon small color="green accent-3" v-on="{ ...tooltip }"
                        >fiber_manual_record</v-icon
                      >
                    </template>
                    <span>'{{ applicationStatus.name }}' is running</span>
                  </v-tooltip>

                  <v-tooltip
                    v-else-if="
                      applicationStatus.state === 'failed' ||
                      applicationStatus.state === 'exited'
                    "
                    bottom
                    :key="`${item.applicationId}-appstatus-failed-${i}`"
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon small color="error" v-on="{ ...tooltip }"
                        >fiber_manual_record</v-icon
                      >
                    </template>
                    <span
                      >'{{ applicationStatus.name }}' is not running, driver
                      status: {{ applicationStatus.message }}</span
                    >
                  </v-tooltip>

                  <v-tooltip
                    v-else
                    bottom
                    :key="`${item.applicationId}-appstatus-success-${i}`"
                  >
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon small color="info" v-on="{ ...tooltip }"
                        >fiber_manual_record</v-icon
                      >
                    </template>
                    <span
                      >{{ applicationStatus.name }} is not running yet, driver:
                      {{ applicationStatus.message }}</span
                    >
                  </v-tooltip>
                  <span :key="`${item.applicationId}-appstatus-name-${i}`">
                    {{ applicationStatus.name }}
                    <br />
                  </span>
                </template>
              </div>
              <div v-else>
                <v-tooltip
                  bottom
                  :key="`${item.applicationId}-appstatus-failed-${i}`"
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon small color="error" v-on="{ ...tooltip }"
                      >fiber_manual_record</v-icon
                    >
                  </template>
                  <span>App not running, status missing</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.applicationStatusesMessage`]="{ item }">
              {{ item.applicationStatuses.message }}
            </template>

            <template v-slot:[`item.lastSeen`]="{ item }">
              {{ item.applicationStatuses.lastSeen | date }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="mr-2"
                x-small
                outlined
                :to="{
                  name: 'deviceSSH',
                  params: {
                    project: item.projectId,
                    device: item.deviceId,
                  },
                }"
                router
              >
                SSH
              </v-btn>
              
              <v-btn
                class="ml-2"
                x-small
                outlined
                :to="{
                  name: 'applicationLogs',
                  params: {
                    project: item.projectId,
                    namespace: item.namespaceId,
                    application: item.applicationId,
                    device: item.deviceId,
                  },
                }"
                router
              >
                LOGS
              </v-btn>

              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    :to="{
                      name: 'applicationLogs',
                      params: {
                        project: item.projectId,
                        namespace: item.namespaceId,
                        application: item.applicationId,
                        device: item.deviceId,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>logs</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';

export default {
  components: {
    PageSubtitle,
    PageTitle,
  },

  data() {
    return {
      tableKey: '',
      e3: null,
      autoRefresh: true,
      availableStatuses: ['pending', 'created', 'running', 'stopped', 'deleting', 'failed', 'unknown', 'exited'],
      selectedStatuses: [],
      headers: [
        { text: 'Device ID', align: 'left', value: 'deviceId', sortable: true },
        { text: 'Device Name', align: 'left', value: 'deviceName', sortable: true },
        { text: 'Status', align: 'left', value: 'applicationStatuses', sortable: true },
        // { text: 'Message', value: 'applicationStatusesMessage', align: 'left' },
        { text: 'Last seen', value: 'lastSeen', align: 'left' },
        // { text: '', value: 'ssh', sortable: false, align: 'center' },
        // { text: '', value: 'logs', sortable: false, align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    namespaceId() {
      return this.$route.params.namespace
    },
    applicationId() {
      return this.$route.params.application
    },
    loading() {
      return this.$store.state.application.loading
    },
    applicationAllocations() {
      if (this.selectedStatuses.length === 0) {
        return this.$store.state.application.applicationAllocations
      }
      return this.$store.state.application.applicationAllocations.filter(item => {
        for (let applicationStatus of item.applicationStatuses) {
          if (this.selectedStatuses.includes(applicationStatus.state)) {
            return true
          }
        }
        return false
      })
    }
  },

  mounted() {
    this.mounted = true
    this.subscribe()
    this.refresh()
  },

  beforeUnmount() {
    this.$store.dispatch('UnsubscribeFromSSE')
  },

  methods: {
    refresh() {
      let q = {
        projectId: this.projectId,
        namespaceId: this.namespaceId,
        applicationId: this.applicationId,
      }
      this.$store.dispatch('ListApplicationAllocations', q)
    },
    refreshOnEvent() {
      // Nothing to do
      if (!this.autoRefresh) {
        console.log('auto refresh is off')
        return
      }
      this.refresh()
    },
    subscribe() {
      let token = this.$store.getters.jwt
      let streamUrl = `/api/projects/${this.projectId}/events?stream=${this.projectId}:applicationStatuses:${this.applicationId}&_t=${token}`

      let q = {
        stream: streamUrl,
        callback: this.refreshOnEvent
      }

      this.$store.dispatch('SubscribeToSSE', q)
    },
  }
}

</script>